<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <div class="top-filters">
      <!-- Date Filter -->
      <date-filter
        v-model="options.date"
        filled
        dense
        class="dateOptions"
        :custom="false"
        @key="(v) => (options.dateType = v)"
      />
    </div>
    <!-- Date Filter -->
    <!-- Sliding Cards -->
    <dashboard-slider-cards
      :is-loading="isLoading"
      :items="dashboardTags"
    />
    <!-- Sliding Cards -->
    <div class="Vehicle-Telematic-chart mt-2">
      <apex-area-charts
        v-if="list?.vehicle_tech !== 'ice'"
        :loading="isLoading"
        name="vehicle"
        title="State Of Charge"
        :labels="chartTimeStamp"
        :value="seriesSOC"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="hh:mm:ss A"
        :yaxis="{ min: 0, max: 100 }"
        unit="%"
      />

      <apex-area-charts
        :loading="isLoading"
        name="vehicle"
        :title="$t('speed')"
        :labels="chartTimeStamp"
        :value="seriesSpeed"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="hh:mm:ss A"
        :yaxis="{ min: 0, max: getMax(seriesSpeed) }"
      />
      <!-- unit="(KM/Hr)" -->

      <apex-area-charts
        :loading="isLoading"
        name="vehicle"
        type="bar"
        :title="$t('distance')"
        :labels="distanceTimeLabel"
        :value="seriesDistance"
        :filter="options.date"
        :xaxis-type="'categories'"
        :xaxis-text="'Date'"
        date-format="DD-MMM"
        tooltip-date-format="DD-MMM-YYYY"
        :yaxis="{ min: 0, max: getMax(seriesDistance) }"
      />
      <!-- unit="(KM)" -->
    </div>
  </v-container>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    DateFilter: () => import("@/components/base/DateDropDown.vue"),
    DashboardSliderCards: () =>
      import("@/components/sections/DashboardSliderCards.vue"),
    // ApexCharts: () => import("@/components/sections/ApexChart.vue"),
    ApexAreaCharts: () => import("@/components/sections/ApexAreaChart.vue"),
  },
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      lastVehicleID: null,
      //
      loopInterval: 15,
      //
      chartTimeStamp: [],
      seriesSOC: [],
      seriesSpeed: [],
      //
      distanceTimeLabel: [],
      seriesDistance: [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "telematicDetails/getIsLoadingTelematicDetails",
      list: "telematicDetails/getTelematicsChart",
    }),
    dashboardTags() {
      const obj = {
        total_distance_travelled: this.list.total_distance_travelled,
        total_average_speed: this.list.total_avg_speed,
        total_average_duration: this.list.total_avg_duration,
        total_duration: this.list.total_duration,
        all_trips: this.list.all_trips,
        // battery_effeciency: this.list.battery_effeciency,
        active_trips: this.list.active_trips_count,
        active_trips_distance: this.list.active_trips_distance,
      };
      return obj;
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function () {
        if (this.name === this.selectedTab) {
          this.fetchData();
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        if (v === this.name) {
          const vd = this.optionFilters.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },
    "options.date": {
      handler: function () {
        this.fetchData();
      },
    },
  },
  methods: {
    fetchData: debounce(async function () {
      const params = {
        ...this.options,
        ...this.optionFilters,
      };
      this.lastVehicleID = this.optionFilters.vehicle_id;
      await this.$store
        .dispatch("telematicDetails/setTelematicCharts", params)
        .then(() => {
          this.setChartData(this.list);
        });
    }, 500),
    setChartData(data) {
      let d = data?.vehicle_trip_telematicData;
      // for time stamp key date_time_gmt_05
      const timeLabel = [];
      const soc = [];
      const speed = [];

      d.forEach((el, i) => {
        // timeLabel.push(el.date_time_gmt_05.split(" ")[1]);
        timeLabel.push(el.date_time_gmt_05);
        // To Set First Value
        if (i == 0) {
          const d = new Date(el.date_time_gmt_05);
          d.setMinutes(0);
          d.setSeconds(0);
          // soc.push({ x: d });
          speed.push({ x: d, y: el.speed });
        }
        // soc.push(el.state_of_charge);
        // soc.push([el.timestamp, el.state_of_charge]);
        soc.push({ x: new Date(el.date_time_gmt_05), y: el.state_of_charge });
        // speed.push(el?.speed);
        speed.push({ x: new Date(el.date_time_gmt_05), y: el.speed });

        // // according to Loop interval which start from 0 index
        // if (i % this.loopInterval == 0) {
        //   timeLabel.push(el.date_time_gmt_05.split(" ")[1]);
        //   soc.push(el.state_of_charge);
        //   speed.push(el?.speed);
        // }
        // // push Last Value if it not in Loop interval
        // else if (i == d.length - 1) {
        //   timeLabel.push(el.date_time_gmt_05.split(" ")[1]);
        //   soc.push(el.state_of_charge);
        //   speed.push(el?.speed);
        // }
      });

      this.chartTimeStamp = timeLabel;
      this.seriesSOC = soc;
      this.seriesSpeed = speed;

      let dis = data?.last_seven_days_distance;

      const distanceLabel = [];
      const distanceSeries = [];

      dis.forEach((el) => {
        // if (i % this.loopInterval == 0) {
        distanceLabel.push(el.trip_date);
        distanceSeries.push({
          x: el.trip_date,
          y: el.total_distance,
        });
        // }
      });

      this.distanceTimeLabel = distanceLabel;
      this.seriesDistance = distanceSeries;

      // last_seven_days_distance
    },

    //
    getMax(val) {
      const arr = val.map((r) => parseInt(r.y));

      const sr = Math.max(...arr);

      return sr > 10 ? sr : 10;
    },
  },
};
</script>
<style lang="sass" scoped>
.Vehicle-Telematic-chart
  display: flex
  justify-content: space-between
  div
    flex: 1

:deep .dateOptions
  .v-card
    padding: 0.5rem
    padding-bottom: 0 !important

@media only screen and (max-width: 960px)
  .Vehicle-Telematic-chart
    flex-direction: column
</style>
